import { createSignal, Match, Show, Switch } from 'solid-js';
import { ActivityIndicator, ComboBox, ComboBoxField, Label, ListBox, Picture, Popover } from '@troon/ui';
import { IconChevronRight, IconMapPin, IconSearchMagnifyingGlass } from '@troon/icons';
import { twMerge } from '@troon/tailwind-preset/merge';
import { gql } from '../graphql';
import { cachedQuery } from '../graphql/cached-get';
import type { ParentProps } from 'solid-js';
import type { IItem, IGroup } from '@troon/ui';
import type { Facility, Location, SearchFacilityFragment, SearchLocationFragment } from '../graphql';

const all = { value: '__all__', href: '/courses/ ' };

type IFacility = IItem<SearchFacilityFragment>;
type IFacilityGroup = IGroup<{ title: string }, SearchFacilityFragment>;
type ILocation = IItem<SearchLocationFragment>;
type ILocationGroup = IGroup<{ title: string }, SearchLocationFragment>;

export const courseSearchQuery = async (query: string) => {
	if (query.length < 3) {
		return [all];
	}

	const res = await searchFacilities({ query });
	if (!res?.search) {
		return [all];
	}

	const items: Array<IItem<Record<string, string>> | ILocationGroup | IFacilityGroup> = [];

	if (res.search.facilities.length) {
		items.push({
			title: 'Courses',
			items: (res.search.facilities as Array<SearchFacilityFragment>).map(
				(facility) =>
					({
						...facility,
						value: facility.slug,
						href: `/course/${facility.slug}/`,
					}) satisfies IFacility,
			),
		} satisfies IFacilityGroup);
	}

	if (res.search.locations.length) {
		items.push({
			title: 'Locations',
			items: (res.search.locations as Array<SearchLocationFragment>).slice(0, 2).map((loc) => {
				const p = new URLSearchParams([['q', `${loc.place}, ${loc.region}`]]);
				return {
					value: `ll-${loc.latitude}-${loc.longitude}`.replace(/[^\w]+/g, '-'),
					...loc,
					href: `/courses/?${p}`,
				} satisfies ILocation;
			}),
		} satisfies ILocationGroup);
	}

	items.push(all);

	return items;
};

export function CourseSearch() {
	const [input, setInput] = createSignal('');

	return (
		<form method="get" action="/courses/">
			<input type="hidden" name="q" value={input()} />
			<ComboBoxField name="search" class="text-black" query={courseSearchQuery} noFilter>
				<Label class="sr-only">Search for courses</Label>
				<ComboBox
					autocomplete="off"
					onInput={(e: InputEvent) => {
						setInput((e.currentTarget as HTMLInputElement).value ?? '');
					}}
					prefixElement={<IconSearchMagnifyingGlass class="mx-1" />}
					class="ps-10"
					placeholder="Where do you want to play?"
				/>
				<Popover sameSize>
					{(props) => (
						<ListBox
							{...props}
							class={twMerge(props.class, 'gap-2 p-2')}
							loadingRenderer={() => <ActivityIndicator class="text-neutral-600">Searching…</ActivityIndicator>}
							groupRenderer={GroupRenderer}
							itemRenderer={ItemRenderer}
						/>
					)}
				</Popover>
			</ComboBoxField>
			<button type="submit" class="sr-only">
				Search
			</button>
		</form>
	);
}

const searchQuery = gql(`
query searchFacilities($query: String!) {
	search: searchFacilitiesV2(query: $query, includeGeocoded: false, includeLocations: true) {
		facilities {
			...SearchFacility
		}
		locations {
			...SearchLocation
		}
	}
}`);

export const fragments = gql(`
fragment SearchFacility on Facility {
	slug
	name
	metadata {
		address {
			city
			state
		}
		hero {
			url
		}
	}
}
fragment SearchLocation on Location {
	latitude
	longitude
	region
	country
	place
}
`);

export const searchFacilities = cachedQuery(searchQuery);

function AllListItem() {
	return (
		<div class="w-full p-2 text-center font-semibold">
			See all courses <IconChevronRight class="text-brand-600" />
		</div>
	);
}

export function GroupRenderer(props: ParentProps<IFacilityGroup | ILocationGroup>) {
	return (
		<section class="flex flex-col gap-1">
			<header role="presentation" class="px-2 text-sm text-neutral-800">
				{(props as ILocationGroup | IFacilityGroup).title}
			</header>
			{props.children}
		</section>
	);
}

export function ItemRenderer(item: IFacility | ILocation) {
	return (
		<Switch>
			<Match when={(item as Location & { value: string }).place}>
				<LocationItem {...(item as Location & { value: string })} />
			</Match>
			<Match when={(item as Facility & { value: string }).slug}>
				<FacilityItem {...(item as Facility & { value: string })} />
			</Match>
			<Match when={item.value === '__all__'}>
				<AllListItem />
			</Match>
		</Switch>
	);
}

function FacilityItem(props: Facility & { value: string }) {
	return (
		<>
			<div class="aspect-square size-16 overflow-hidden rounded-lg bg-neutral-500">
				<Show when={props.metadata?.hero?.url}>
					<Picture
						src={props.metadata!.hero!.url}
						sizes={[
							[256, 256],
							[128, 128],
						]}
						alt=""
						width={128}
						height={128}
						class="aspect-square"
					/>
				</Show>
			</div>
			<div class="flex grow flex-col items-start justify-center">
				<h3 class="font-semibold">{props.name}</h3>
				<Show when={props.metadata?.address?.city || props.metadata?.address?.state}>
					<p class="text-sm">
						<Show when={props.metadata?.address?.city}>{props.metadata?.address?.city}, </Show>
						{props.metadata?.address?.state}
					</p>
				</Show>
			</div>
			<IconChevronRight class="self-center text-brand-600" />
		</>
	);
}

function LocationItem(props: Location & { value: string }) {
	return (
		<>
			<div class="flex aspect-square size-16 items-center justify-center overflow-hidden rounded-lg bg-brand-100 text-brand">
				<IconMapPin />
			</div>
			<div class="flex grow flex-col items-start justify-center">
				<h3 class="font-semibold">
					{props.place}, {props.region}
				</h3>
			</div>
			<IconChevronRight class="self-center text-brand-600" />
		</>
	);
}
