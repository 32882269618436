import { IconCalendar, IconCaretDownMd, IconClock, IconUsers } from '@troon/icons';
import { twJoin } from '@troon/tailwind-preset/merge';
import { Button, Field, Input, Label, Option, Select, TextField } from '@troon/ui';
import dayjs from 'dayjs';
import { createSignal, For, Show } from 'solid-js';
import { useNavigate } from '@solidjs/router';
import { timeframes } from '../modules/date-formatting';
import { SearchLocations } from './search-locations';
import type { ILocation } from './search-locations';

type Props = {
	card?: string;
	noButton?: boolean;
	onSelectLocation?: (loc: ILocation) => void;
	onSelectPlayers?: (count: number) => void;
	onSelectTimeRange?: (startAt: number, endAt: number) => void;
	onSelectDate?: (date: Date) => void;
	query?: string;
	startAt?: number;
	endAt?: number;
	date?: Date;
	maxDate?: Date;
	players?: number;
};

export function TeeTimeSearch(props: Props) {
	let formRef: HTMLFormElement;
	const [loc, setLoc] = createSignal<ILocation>();
	const navigate = useNavigate();

	return (
		<form
			method="get"
			action="/tee-times"
			class="flex flex-row flex-wrap items-stretch xl:flex-nowrap"
			ref={formRef!}
			onSubmit={(e) => {
				e.preventDefault();
				const location = loc();
				const data = new FormData(formRef);
				if (location) {
					data.set('lat', `${location.latitude}`);
					data.set('lon', `${location.longitude}`);
					data.set('query', `${location.place}, ${location.region}`);
				}
				const [startAt, endAt] = timeframes[data.get('time') as keyof typeof timeframes] ?? [0, 24];
				if (startAt && endAt) {
					data.set('startAt', `${startAt}`);
					data.set('endAt', `${endAt}`);
				}
				data.delete('time');
				const params = new URLSearchParams(Object.fromEntries(data) as Record<string, string>);
				navigate(`/tee-times/?${params.toString()}`);
			}}
		>
			<TeeTimeSearchFields
				{...props}
				onSelectLocation={(loc) => {
					setLoc(loc);
				}}
			/>
		</form>
	);
}

export function TeeTimeSearchFields(props: Props) {
	return (
		<>
			<div
				class={twJoin(
					'shrink basis-full overflow-hidden rounded-xl rounded-b-none border border-neutral  p-2 xl:rounded-e-none xl:rounded-s-lg xl:border-b',
					props.noButton ? 'xl:basis-2/5' : 'xl:basis-2/6',
				)}
			>
				<SearchLocations
					card={props.card}
					defaultValue={props.query}
					onSelectLocation={props.onSelectLocation ?? (() => {})}
				/>
			</div>

			<TextField
				name="date"
				class={twJoin(
					'shrink grow basis-1/2 overflow-hidden border-x border-b border-neutral xl:border-y xl:border-s-0',
					props.noButton ? 'xl:basis-1/5' : 'xl:basis-1/6',
				)}
			>
				<div class="h-full p-2">
					<Label class="sr-only">Date</Label>
					<Input
						type="date"
						min={dayjs().format('YYYY-MM-DD')}
						max={props.maxDate && dayjs(props.maxDate).format('YYYY-MM-DD')}
						value={dayjs(props.date).format('YYYY-MM-DD')}
						class="rounded-none border-0 ps-8 focus-visible:ring-2 focus-visible:ring-brand-100"
						prefixElement={<IconCalendar class="text-brand" />}
						suffixElement={<IconCaretDownMd class="hidden text-xl md:block" />}
						onChange={(e) => {
							props.onSelectDate && props.onSelectDate(dayjs(e.currentTarget.value).toDate());
						}}
					/>
				</div>
			</TextField>

			<Field
				class={twJoin(
					'shrink grow basis-1/2 overflow-hidden border-b border-e border-neutral xl:border-y',
					props.noButton ? 'xl:basis-1/5' : 'xl:basis-1/6',
				)}
				name="players"
			>
				<div class="p-2">
					<Label class="sr-only">Players</Label>
					<Select
						prefixElement={<IconUsers class="text-brand" />}
						suffixElement={<IconCaretDownMd class="hidden text-xl md:block" />}
						class="rounded-none border-0 ps-8 focus-visible:ring-2 focus-visible:ring-brand-100"
						onChange={(e) => {
							props.onSelectPlayers && props.onSelectPlayers(parseInt(e.currentTarget.value, 10));
						}}
					>
						{
							// eslint-disable-next-line solid/prefer-for
							new Array(4).fill(null).map((_, i) => (
								<Option value={i + 1} selected={props.players === i + 1}>
									{i + 1} player{i > 0 ? 's' : ''}
								</Option>
							))
						}
					</Select>
				</div>
			</Field>

			<Field
				class={twJoin(
					'shrink grow basis-full overflow-hidden border border-t-0 border-neutral xl:rounded-b-none xl:border-t',
					props.noButton ? 'xl:basis-1/5 xl:rounded-e-lg xl:border-s-0' : 'rounded-b-lg xl:basis-1/6 xl:border-x-0',
				)}
				name="time"
			>
				<div class="p-2">
					<Label class="sr-only">Time</Label>
					<Select
						prefixElement={<IconClock class="text-brand" />}
						class="rounded-none border-0 ps-8 focus-visible:ring-2 focus-visible:ring-brand-100"
						onChange={(e) => {
							const [startAt, endAt] = timeframes[e.currentTarget.value] ?? [0, 24];
							props.onSelectTimeRange && props.onSelectTimeRange(startAt, endAt);
						}}
					>
						<For each={Object.entries(timeframes)}>
							{([label, [start, end]]) => (
								<Option selected={start === props.startAt && end === props.endAt}>{label}</Option>
							)}
						</For>
					</Select>
				</div>
			</Field>

			<Show when={!props.noButton}>
				<div class="flex shrink basis-full items-stretch justify-center overflow-hidden pt-4 xl:basis-auto xl:rounded-e-lg xl:border xl:border-s-0 xl:border-neutral xl:p-2">
					<Button type="submit" appearance="primary" class="w-full text-nowrap">
						Find a tee time
					</Button>
				</div>
			</Show>
		</>
	);
}
